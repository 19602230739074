import React from 'react';
import styles from './main-loader.module.scss';
import { environment } from '@environment';

interface MainLoaderProps {
	color?: string;
	customClass?: string;
}

export const MainLoader = ({ color = 'purple', customClass }: MainLoaderProps) => {
	return (
		<div className={`${styles[customClass]} flexbox colls centered`}>
			<img
				src={`${environment.web_cdn_url}web/assets/img/landing/landing-loader-${color}.gif`}
				className={styles.mainloader}
			/>
		</div>
	);
};

export default MainLoader;
