import React from 'react';
import styles from './gift-box.module.scss';
import { useTranslation } from 'next-i18next';
import { environment } from '@environment';
import { appendQueryParamstoUrl, getSidFromUrl } from '@helpers/helper';
import { useRouter } from 'next/router';
import { filterParams } from '@helpers/operators-helper';
import AsyncImage from '@components/async-image/async-image';

interface GiftBoxProps {
	urlParams?: any;
	showRedeem?: boolean;
}

export const GiftBoxComponent = ({ showRedeem, urlParams = {} }: GiftBoxProps) => {
	const { t } = useTranslation();
	const router = useRouter();
	const innerWebQP = Boolean(Number(router.query.innerweb));
	const darkModeQP = Boolean(Number(router.query.dark_mode));
	const appsid = getSidFromUrl();

	const redirectLink = (urlPage) => {
		const params = {
			...urlParams,
			sid: appsid,
			innerweb: innerWebQP ? 1 : 0
		};
		filterParams(params);
		const url = appendQueryParamstoUrl(`/${urlPage}`, params);

		if (innerWebQP) {
			router.push(url);
		} else {
			window.open('https://www.anghami.com' + url, '_blank');
		}
	};

	return (
		<>
			<div
				className={`${styles['gift-wrap']} ${darkModeQP ? styles.darkMode : ''} flexbox centered`}
			>
				<div className={styles.giftbox_wrap_img}>
					<AsyncImage
						isFullWidth
						imgSrc={environment.web_cdn_url + 'web/assets/img/gift/gift-updated.png'}
						alt="Gift Image"
						width={300}
						height={205}
						imageClass="gift_box_img"
					/>
				</div>
				<div className={styles['gift-spacing-mobile']}>
					<div className={styles['gift-title']}>{t('manage_account_gift_title')}</div>
					<div className={styles['gift-subtitle']}>{t('manage_account_gift_desc')}</div>
					<div className={`${styles['button-wrap']} flexbox`}>
						<button
							type="button"
							className={`default-btn dark-purple ${styles['gift-btn']}`}
							onClick={() => {
								redirectLink('gifts');
							}}
						>
							{t('manage_account_gift_cta_buy')}
						</button>
					</div>
				</div>
			</div>
			{showRedeem && (
				<div
					className={`${styles['redeem-link']} ${
						darkModeQP ? styles.darkMode : ''
					} flexbox centered`}
					onClick={() => {
						redirectLink('redeem');
					}}
				>
					{t('subscreen_gift_f1')}
				</div>
			)}
		</>
	);
};

export default GiftBoxComponent;
