import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { NextSeo } from 'next-seo';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { PageWrapper } from '@components/pagewrapper/pagewrapper';
import { PlusComponent } from '@components/plus/plus';
import getSubscribe from '@anghami/neogateway/dist/endpoints/getSubscribe';
import { GiftBoxComponent } from '@components/gift-box';
import { Faq } from '@components/faq';
import { userContext } from '@components/auth/auth.context';
import styles from '@components/plus/plus.module.scss';
import { environment } from '@environment';
import cookie from '@helpers/cookies';
import { useTranslation } from 'next-i18next';
import withAuth from '@components/auth';
import { gateway, initNeoGatewayServer } from '@helpers/neogateway';
import MainLoader from '@components/main-loader';
import GenericBenefits from '@components/generic-benefits';
import { ANGHAMI_PLUS_BENEFITS } from '@enums/utils-constants';

export const PlusPage = ({ query }) => {
	const auth = React.useContext(userContext);
	const { i18n, t } = useTranslation();
	const lang = i18n.language || cookie.getCookie('userlanguageprod') || 'en';

	const [subresponse, setsubresponse] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const isDarkMode = Boolean(Number(query?.dark_mode) === 1);

	const metas = {
		en: {
			title: 'Anghami Plus - Get 1 month Plus for Free',
			description: 'The ultimate music experience with Anghami Plus',
			image: `${environment.new_web_cdn_url}/web/assets/img/new-metas/plus/anghamiplus_en.png`
		},
		fr: {
			title: 'Anghami Plus - Profite d\'1 mois gratuit d’Anghami Plus',
			description: 'The ultimate music experience with Anghami Plus',
			image: `${environment.new_web_cdn_url}/web/assets/img/new-metas/plus/anghamiplus_en.png`
		},
		ar: {
			title: 'أنغامي بلَس  - استمتع بـشهر من أنغامي بلَس مجاناً',
			description: 'تحربة موسيقيّة استثنائيّة مع أنغامي بلَس',
			image: `${environment.new_web_cdn_url}/web/assets/img/new-metas/plus/anghamiplus_ar.png`
		}
	};

	const renderedMetas = (
		<NextSeo
			title={metas[lang].title}
			description={metas[lang].description}
			canonical="https://www.anghami.com/plus"
			openGraph={{
				url: 'https://www.anghami.com/plus',
				title: metas[lang].title,
				description: metas[lang].description,
				images: [
					{
						url: metas[lang].image,
						alt: metas[lang].title
					}
				],
				site_name: 'Anghami'
			}}
			twitter={{
				handle: '@anghami',
				site: '@anghami',
				cardType: 'summary_large_image'
			}}
		/>
	);

	useEffect(() => {
		fetchPlans();
	}, []);

	async function fetchPlans() {
		const response = await gateway.callEndpoint(getSubscribe, {
			...query
		});
		if (response?.data) {
			setsubresponse(response.data);
		} else {
			setsubresponse({
				error: {
					message: t('oops_tryagain')
				}
			});
		}

		setIsLoading(false);
	}

	return (
		<>
			{/* <Head>
				<link
					rel="preconnect"
					href={`${environment.web_cdn_url}web/assets/img/${
						lang === 'ar'
							? 'logos/AnghamiPlusLogo_AR_WhiteMode.png'
							: 'new-anghami-logos/anghami-plus-logo.png'
					}`}
				/>
				<link
					rel="preconnect"
					href={`${environment.web_cdn_url}web/assets/img/gift/gift-updated.png}`}
				/>
			</Head> */}
			<PageWrapper hideHeader={false} hideFooter={false}>
				{renderedMetas}
				<div
					className={`${styles['main-plus-wrapper']} ${
						isDarkMode ? styles.dark_mode : ''
					} flexbox colls`}
				>
					{isLoading ? (
						<MainLoader customClass="min_height_50" />
					) : (
						<PlusComponent
							data={subresponse}
							params={query}
							user={auth.user}
							isDarkMode={isDarkMode}
						/>
					)}
					<div className="spacer" />
					<GiftBoxComponent />
					<div className="spacer md" />
					<GenericBenefits
						showTitle
						title={t('plus_features_section_title')}
						type="wrapped_box"
						logo={`${environment.new_web_cdn_url}/web/assets/img/${
							isDarkMode
								? 'logos/anghami-logo-new-white.png'
								: 'new-anghami-logos/anghami-plus-logo.png'
						}`}
						color="#00D1E8"
						features={ANGHAMI_PLUS_BENEFITS}
						customSpacing="0rem 2rem 1rem"
						imgSize={40}
						isDarkMode={isDarkMode}
					/>
					<Faq
						isShowHelpCenter
						isDarkMode={isDarkMode}
						faqKey="free,LB,touch,alfa,nophone,web,web,usesmultipledevices"
					/>
				</div>
			</PageWrapper>
		</>
	);
};

PlusPage.propTypes = {
	query: propTypes.any
};

export async function getServerSideProps(ctx): Promise<any> {
	const { pageProps } = await initNeoGatewayServer(ctx);
	return {
		props: {
			...pageProps,
			...(await serverSideTranslations(ctx.locale, ['common'])),
			query: ctx.query
		}
	};
}

export default withAuth(PlusPage);
