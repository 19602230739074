import React from 'react';
import styles from './generic-benefits.module.scss';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Carousel from '@components/carousel/carousel';
import { imageLoader } from '@components/async-image/async-image';

interface IFeatureData {
	title: string;
	image: string;
	alt: string;
	description?: string;
}

interface GenericBenefitsProps {
	type?: 'rounded' | 'box' | 'square' | 'wrapped_box' | 'individual_box';
	logo?: string;
	title?: string;
	subtitle?: string;
	titleStyles?: any;
	showTitle?: boolean;
	showDescription?: boolean;
	color?: string;
	imgSize?: number;
	extraImgPadding?: string;
	customSpacing?: string;
	features: IFeatureData[];
	customClass?: any;
	carouselWrapperStyle?: any;
	isDarkMode?: boolean;
	isCarousel?: boolean;
}

export const GenericBenefits = ({
	type = 'rounded',
	logo,
	title,
	subtitle,
	titleStyles,
	color,
	showTitle,
	showDescription = true,
	extraImgPadding,
	customSpacing,
	features,
	imgSize = 40,
	customClass,
	carouselWrapperStyle,
	isDarkMode,
	isCarousel
}: GenericBenefitsProps) => {
	const { t } = useTranslation();

	const getCarouselImages = () => {
		if (features) {
			const _features = features.map((elt, index) => {
				const _img = {
					id: index + 1,
					src: elt?.image,
					title: elt?.title
				};
				return _img;
			});
			return _features;
		}

		return [];
	};

	const _carouselImages = getCarouselImages();

	return (
		<div
			className={`flexbox colls ${styles.GenericBenefits_wrapper} ${styles[type]} ${
				isDarkMode ? styles.dark_mode : ''
			}  ${styles[customClass]}`}
			style={{
				padding: customSpacing || '2rem 2rem 4rem'
			}}
		>
			{logo && <img src={logo} className={styles.logo} />}
			{showTitle && (
				<div className={styles.title} style={titleStyles}>
					{title || t('allfeatures_title')}
				</div>
			)}
			{subtitle && (
				<div className={styles.subtitle} style={titleStyles}>
					{subtitle}
				</div>
			)}
			{isCarousel ? (
				<Carousel
					data={null}
					images={_carouselImages}
					isCarouselOnTop={false}
					mainCarouselClass={customClass}
					isDarkMode={isDarkMode}
					backgroundColor={color}
					wrapperStyle={carouselWrapperStyle}
				/>
			) : (
				<div className={`${styles.body_wrapper} flexbox ${styles[type]}`}>
					{features?.map((elt) => {
						return (
							<div key={elt.alt} className={`${styles.benefit_wrapper} flexbox`}>
								<div
									className={`${styles.img_wrapper} flexbox centered`}
									style={{
										backgroundColor: color,
										padding: extraImgPadding || 'unset'
									}}
								>
									{type === 'individual_box' ? (
										<div className={styles.individual_box_img}>
											<Image
												src={elt.image}
												alt={elt.alt}
												width={imgSize}
												height={imgSize}
												layout="fill"
												objectFit="cover"
												loading="lazy"
												loader={imageLoader}
											/>
										</div>
									) : (
										<Image
											src={elt.image}
											alt={elt.alt}
											width={imgSize}
											height={imgSize}
											layout="intrinsic"
											objectFit="cover"
											loading="lazy"
											loader={imageLoader}
										/>
									)}
								</div>
								<div className={styles.desc_wrapper}>
									<div className={styles.label}>{t(elt.title)}</div>
									{elt.description && showDescription && (
										<div className={styles.description}>{t(elt.description)}</div>
									)}
								</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default GenericBenefits;
