import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'next-i18next';
import styles from './plus.module.scss';
import { environment } from '@environment';
import { isMobile, getQueryParamsfromUrl, appendQueryParamstoUrl } from '@helpers/helper';
import { useRouter } from 'next/router';
import reportAmplitudeEvent from '@helpers/analytics';
import { AmplitudeEvents } from '@enums/amplitude';
import { userContext } from '@components/auth/auth.context';
import { PlanComponent } from '@components/sub-plan/plan';
import AsyncImage from '@components/async-image/async-image';
import LinkIconBlack from '../../public/static/icons/link.svg';
import LinkIconWhite from '../../public/static/icons/white-link.svg';
import { gateway } from '@helpers/neogateway';
import postTapfiliateCustomer from '@anghami/neogateway/dist/endpoints/postTapfiliateCustomer';
import cookie from '@helpers/cookies';

interface IPlusProps {
	data: any;
	params: any;
	user: any;
	isDarkMode?: boolean;
}

const SECTION_TYPE_DISCLAIMER = {
	lbp: 'plus_disclaimer_sayrafa_lbp',
	default: 'cancel_anytime'
};

export const PlusComponent = ({ data, params, user, isDarkMode }: IPlusProps) => {
	const { t, i18n } = useTranslation();
	const lang = i18n.language;
	const router = useRouter();
	const auth = React.useContext(userContext);

	const [selectedSection, setSelectedSection] = useState(null);
	const [sectionsLst, setSectionsLst] = useState([]);
	const [visiblePlans, setVisiblePlans] = useState([]);
	// flag that checks if any related plan has any multiplier,
	// used mainly for UI (i.e. controls the height of the card)
	const [isRelatedPlans, setIsRelatedPlans] = useState(false);
	// flag that checks if any plan has related plans,
	// used mainly for UI (i.e. controls the height of the card)
	const [isMultiplier, setIsMultiplier] = useState(false);
	const [mainError, setMainError] = useState(null);
	const [customDisclaimer, setCustomDisclaimer] = useState(t(SECTION_TYPE_DISCLAIMER.default));

	useEffect(() => {
		const amplitudeObject: any = {};

		if (data?.sections?.length > 0 && data?.plans) {
			getAvailableSections(data.sections, data.plans);
		} else if (data?.error) {
			setError(data.error);
		}

		reportAmplitudeEvent(AmplitudeEvents.viewSubscribe, amplitudeObject);
	}, [data, mainError]);

	useEffect(() => {
		if (user && params?.ref) {
			const tapfiliateClickId = cookie.getCookie('click_id');
			if (tapfiliateClickId?.length > 0) {
				gateway.callEndpoint(postTapfiliateCustomer, {
					click_id: tapfiliateClickId
				});
			}
		}
	}, [user]);

	function setError(error) {
		const message = error.message || t('oops_tryagain');
		setMainError(message);
	}

	/**
	 * @description returns the list of available sections to show
	 * the user based on eligible plans
	 * @param lst sections in API response
	 */
	function getAvailableSections(lst, plans) {
		const sections = [];
		lst.forEach((section) => {
			const selectedplans = plans.filter((plan) => plan.section_type === section.type);
			if (selectedplans?.length > 0) {
				sections.push({
					...section,
					isSelected: Boolean(params?.payment_type === section.type)
				});
			}
		});
		setDefaultSection(sections);
	}

	function setDefaultSection(sections) {
		let selectedSection = sections.find((elt) => Boolean(elt.isSelected));
		/**
		 * if param_type specifying which section should be highlited to the user,
		 * by default we set the first one
		 */
		if (!selectedSection) {
			sections[0].isSelected = true;
			selectedSection = sections[0];
		}

		getCustomDisclaimer(selectedSection?.type);
		setSelectedSection(selectedSection);
		getVisiblePlans(selectedSection.type);
		setSectionsLst(sections);
	}

	function getCustomDisclaimer(sectionType) {
		const _custom = Object.keys(SECTION_TYPE_DISCLAIMER).find(
			(keySection) => keySection === sectionType
		);
		if (_custom) {
			if (typeof SECTION_TYPE_DISCLAIMER[_custom] === 'string') {
				setCustomDisclaimer(t(SECTION_TYPE_DISCLAIMER[_custom]));
			} else if (typeof SECTION_TYPE_DISCLAIMER[_custom] === 'object') {
				const _userCountry = params?.country?.toUpperCase() || user?.country?.toUpperCase() || '';
				setCustomDisclaimer(
					t(SECTION_TYPE_DISCLAIMER[_custom][_userCountry] || SECTION_TYPE_DISCLAIMER.default)
				);
			}
		} else {
			setCustomDisclaimer(t(SECTION_TYPE_DISCLAIMER.default));
		}
	}

	function onSectionChange(section) {
		reportAmplitudeEvent(AmplitudeEvents.selectPlansSection, {
			sectiontype: section.type
		});
		const currSelectedSec = sectionsLst.find((section) => section.isSelected);
		if (currSelectedSec?.type !== section.type) {
			resetSectionsLst(section.type);
			getVisiblePlans(section.type);
			getCustomDisclaimer(section?.type);
		}
	}

	/**
	 * @description In case of reselection for new section reset the sections' statuses
	 */
	function resetSectionsLst(selectedSectionType?) {
		const sections = sectionsLst.map((section) => {
			return {
				...section,
				isSelected: Boolean(section.type === selectedSectionType)
			};
		});
		setDefaultSection(sections);
	}

	/**
	 * @description func that sets the visible plans for each selected section
	 */
	function getVisiblePlans(selectedSectionType?) {
		const type =
			!selectedSectionType && sectionsLst.length > 0 ? sectionsLst[0].type : selectedSectionType;
		if (data?.plans?.length > 0) {
			let plans = data.plans.filter((plan) => plan.section_type === type && !plan.hidden);
			plans = plans.map((plan) => {
				const relatedplans = getRelatedPlans(plan);
				return {
					...plan,
					relatedplans
				};
			});
			reorderVisiblePlans(plans);
		}
	}

	/**
	 * @description related plans are subplans that belong to a main parent plan
	 * i.e Monthly plan's related plans are: 6 months plan and yearly plan
	 * @param plan parent plan that has a set of subplans
	 */
	function getRelatedPlans(plan) {
		let relplans = [];
		if (data?.relatedplans?.length > 0) {
			relplans = data?.relatedplans.find((relatedPlan) => {
				return relatedPlan.find((elt) => elt === Number(plan.planid));
			});
			if (relplans) {
				// fetches the related plan details through id from response.plans
				relplans = data?.plans.filter((elt) => relplans.includes(Number(elt.planid)));
				// removes the parent plan from the list
				relplans = relplans.filter((elt) => elt.planid !== plan.planid);
			}
		}

		return relplans;
	}

	function getMainPlan(lst) {
		let mainplan = lst.find((plan) => data?.mainplanid.find((elt) => elt === plan.planid)) || null;
		let mainplanbgd;
		if (mainplan?.background) {
			mainplanbgd = {
				backgroundImage: 'url(\'' + mainplan.background + '\')',
				backgroundColor: '#9AA3A8',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				backgroundPosition: 'center'
			};
			mainplan = {
				...mainplan,
				mainbgd: mainplanbgd
			};
		}

		return mainplan;
	}

	function reorderVisiblePlans(lst) {
		const mainplan = getMainPlan(lst);
		if (mainplan && lst?.length > 0) {
			const mainplanIndex = lst.findIndex((plan) => plan.planid === mainplan.planid);
			lst[mainplanIndex] = mainplan;
		}

		checkMultiplier(lst);
		checkRelatedPlans(lst);
		setVisiblePlans(lst);
	}

	function appendParamsToUrl(url, queryParams = params) {
		let updatedUrl = isMobile() ? url : url.replace('http://m.angha.me', 'https://anghami.com');
		const { extraParams, baseUrl } = getQueryParamsfromUrl(updatedUrl);

		if (queryParams) {
			if (extraParams) {
				queryParams = {
					...queryParams,
					...extraParams
				};
			}

			updatedUrl = appendQueryParamstoUrl(baseUrl, queryParams);
		}

		return updatedUrl;
	}

	function onPlanAction(plan) {
		reportAmplitudeEvent(AmplitudeEvents.onPlanClick, {
			title: plan.title,
			id: plan.planid,
			action: plan.action,
			type: plan.section_type
		});
		if (plan.action) {
			if (selectedSection?.type === 'operator') {
				window.location.href = appendParamsToUrl(plan.action);
			} else if (user) {
				if (plan.action.includes('studentoffer')) {
					const url = appendParamsToUrl(plan.action);
					router.push(url);
				} else {
					window.location.href = plan.action;
				}
			} else {
				auth.setLoginModalState(true);
			}
		} else {
			auth.setLoginModalState(true);
		}
	}

	function checkMultiplier(plans) {
		const multiplierExists = Boolean(
			plans.find((plan) =>
				plan.relatedplans ? plan.relatedplans.find((elt) => elt.multiplier) : false
			)
		);
		setIsMultiplier(multiplierExists);
	}

	function checkRelatedPlans(plans) {
		setIsRelatedPlans(plans.find((elt) => elt.relatedplans?.length > 0));
	}

	function renderPartnersCard() {
		const partnersByCountry = {
			LB: [
				{
					src: `${environment.new_web_cdn_url}/web/assets/img/plus/vouchers/toters_updated.png`,
					link: 'https://www.totersapp.com/anghami'
				},
				{
					src: `${environment.new_web_cdn_url}/web/assets/img/plus/vouchers/cashunited_updated.png`
				},
				{
					src: `${environment.new_web_cdn_url}/web/assets/img/plus/vouchers/libanpost_updated.png`
				},
				{
					src: `${environment.new_web_cdn_url}/web/assets/img/plus/vouchers/whish_updated.png`
				}
			],
			EG: [
				{
					src: `${environment.new_web_cdn_url}/web/assets/img/plus/vouchers/fawry.png`
				},
				{
					src: `${environment.new_web_cdn_url}/web/assets/img/plus/vouchers/virgin.png`
				},
				{
					src: `${environment.new_web_cdn_url}/web/assets/img/plus/vouchers/b_tech.png`
				}
			]
		};
		const cardDataByCountry = {
			LB: {
				description: 'plus_voucher_card_description_lb',
				disclaimer: 'plus_voucher_card_point_2_lb'
			},
			EG: {
				description: 'plus_voucher_card_description_eg',
				disclaimer: 'plus_voucher_card_point_2_eg'
			}
		};

		const option1PO = t('plus_voucher_card_point_1').split('%@');
		const option2PO = t(
			cardDataByCountry[data?.show_vouchers_country?.toUpperCase()]?.disclaimer
		).split('%@');

		return (
			<div className="flexbox colls centered">
				<div className={`${styles.vouchers_card} flexbox colls corners`}>
					<div>
						<div className={styles.vouchers_title}>{t('plus_voucher_card_title')}</div>
						<div className={styles.vouchers_subtitle}>
							{t(cardDataByCountry[data?.show_vouchers_country?.toUpperCase()]?.description)}
						</div>
					</div>
					<div className={`${styles.vouchers_description} flexbox colls`}>
						{data?.show_vouchers_country === 'LB' && (
							<div className={`${styles.vouchers_notice_wrapper} flexbox`}>
								<img
									className={styles.vouchers_notice_img}
									src={`${environment.new_web_cdn_url}/web/assets/img/plus/vouchers/cash_icon.png`}
								/>
								<div className={styles.vouchers_notice_desc}>
									{option1PO[0]} <span className="bold">{option1PO[1]}</span> {option1PO[2]}{' '}
									<span className="bold">{option1PO[3]}</span>
								</div>
							</div>
						)}
						<div className={`${styles.vouchers_notice_wrapper} flexbox`}>
							<img
								className={styles.vouchers_notice_img}
								src={`${environment.new_web_cdn_url}/web/assets/img/plus/vouchers/availability_icon.png`}
							/>
							<div className={styles.vouchers_notice_desc}>
								{option2PO[0]} <span className="bold">{option2PO[1]}</span>
							</div>
						</div>
					</div>
					<div>
						<div className={`${styles.partners_wrapper} flexbox centered`}>
							{partnersByCountry[data?.show_vouchers_country?.toUpperCase()]?.map(
								(partner, index) => {
									return (
										// eslint-disable-next-line react/no-array-index-key
										<div key={`id-${index}`} className={`flexbox colls ${styles.partner_box}`}>
											<img className={styles.partner_img} src={partner?.src} />
											<div
												className={`${styles.vouchers_link_wrap} flexbox`}
												onClick={() => {
													window.location.href = partner?.link;
												}}
											>
												{partner?.link && (
													<>
														<img
															src={isDarkMode ? LinkIconWhite.src : LinkIconBlack.src}
															alt="Link Icon"
															width="10"
															height="10"
														/>
														<div className={styles.vouchers_link}>{t('Learn More')}</div>
													</>
												)}
											</div>
										</div>
									);
								}
							)}
						</div>
					</div>
				</div>
				<div className={styles.redeem_cta_voucher_wrapper}>
					<div className="force-same-line">{t('plus_voucher_card_redeem_text')}</div>
					<div
						className={`${styles.redeem_cta_voucher} force-same-line`}
						onClick={() => {
							window.location.href = 'https://www.anghami.com/redeem';
						}}
					>
						{t('plus_voucher_card_redeem_cta')}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={isDarkMode ? 'dark_mode' : ''}>
			<div className={`${styles['plus-main-header']} flexbox colls`}>
				<div className={styles['plus-header-title-logo']}>
					<AsyncImage
						isFullWidth
						imgSrc={`${environment.new_web_cdn_url}/web/assets/img/${
							isDarkMode
								? 'logos/anghami-logo-new-white.png'
								: lang === 'ar'
									? 'logos/AnghamiPlusLogo_AR_WhiteMode.png'
									: 'new-anghami-logos/anghami-plus-logo.png'
						}`}
						alt="AnghamiPlus"
						width={150}
						height={75}
					/>
				</div>
				{sectionsLst?.length > 1 && (
					<div className={styles['plus-header-subtitle']}>{t('subscreen_main_title')}</div>
				)}
			</div>
			{mainError && <div className={styles['main-error']}>{mainError}</div>}
			<div className={styles['plus-main-body']}>
				{sectionsLst?.length > 1 && (
					<div
						className={`${styles['plus-sections-wrapper']}
						${customDisclaimer.toString() === t(SECTION_TYPE_DISCLAIMER.default) ? '' : styles.custom_section}
						flexbox`}
					>
						{sectionsLst.map((section) => {
							return (
								<div
									key={section.type}
									className={`${styles['plus-section']} ${
										section.isSelected ? styles.selected : ''
									}`}
									onClick={() => {
										onSectionChange(section);
									}}
								>
									{section.title}
								</div>
							);
						})}
					</div>
				)}
				<div
					className={`flexbox ${
						customDisclaimer.toString() === t(SECTION_TYPE_DISCLAIMER.default)
							? 'colls'
							: 'colls-reversed'
					}`}
				>
					{visiblePlans?.length > 0 && (
						<div
							className={`${styles['cards-main-wrapper']} flexbox centered`}
							style={{
								overflowX:
									Number(visiblePlans?.length) + (data?.show_vouchers ? 1 : 0) > 4
										? 'auto'
										: 'unset',
								justifyContent:
									Number(visiblePlans?.length) + (data?.show_vouchers ? 1 : 0) > 4
										? 'flex-start'
										: 'center'
							}}
						>
							{data?.show_vouchers && selectedSection?.type !== 'operator' && renderPartnersCard()}
							{visiblePlans.map((plan) => {
								return (
									<div key={plan.planid} className={styles['mobile-plan-wrapper']}>
										<PlanComponent
											plan={plan}
											isRelatedPlans={Boolean(isRelatedPlans)}
											isMultiplier={Boolean(isMultiplier)}
											isDarkMode={isDarkMode}
											onPlanAction={(e) => {
												onPlanAction(e);
											}}
										/>
									</div>
								);
							})}
							{data?.show_vouchers && selectedSection?.type === 'operator' && renderPartnersCard()}
						</div>
					)}
					<div
						className={`${styles['disclaimer-wrapper']}
						${customDisclaimer.toString() === t(SECTION_TYPE_DISCLAIMER.default) ? '' : styles.custom}
						flexbox centered`}
					>
						<div className={styles['cancel-disclaimer']}>
							{customDisclaimer}
							<span
								className={styles.terms}
								onClick={() => {
									window.open('https://anghami.com/legal', '_blank');
								}}
							>
								{t('terms_conditions_title')}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

PlusComponent.propTypes = {
	data: PropTypes.object.isRequired,
	params: PropTypes.object,
	user: PropTypes.object
};

export default PlusComponent;
