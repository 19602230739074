import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'next-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { environment } from '@environment';
import styles from './plan.module.scss';

interface IPlanProps {
	plan: {
		planid: string;
		mainbgd?: any;
		bigimage?: string;
		title: string;
		price: string;
		currency: string;
		duration: string;
		benefits?: any[];
		planbutton: string;
		trial: string;
		relatedplans?: any[];
		section_type?: string;
	};
	isRelatedPlans: boolean;
	isMultiplier: boolean;
	isDarkMode?: boolean;
	onPlanAction: (e) => void;
}

export const PlanComponent = ({
	plan,
	isRelatedPlans,
	isMultiplier,
	isDarkMode,
	onPlanAction
}: IPlanProps) => {
	const { t } = useTranslation();
	// There's plans that have rectangle logo, that needs differnt width
	// TODO: api should return this as a boolean value for later
	const rectangleLogoPlans = ['1289'];
	return (
		<div
			key={plan?.planid}
			className={`${styles['plan-card']} ${plan?.mainbgd ? styles.mainplan : ''}
				${styles[plan.section_type]}
				${!isRelatedPlans ? styles['sm-height'] : ''}
				${isDarkMode ? styles.dark_mode : ''}
				${!isMultiplier ? styles['md-height'] : ''} flexbox colls corners`}
			style={plan?.mainbgd}
		>
			<div
				className={`${styles['plan-logo']} ${
					rectangleLogoPlans.includes(plan.planid) && styles.rectangle
				} flexbox spacer md`}
			>
				{plan?.bigimage && <LazyLoadImage src={plan?.bigimage} />}
			</div>
			<div className={styles['plan-title']}>{plan?.title}</div>
			<div className="flexbox spacer md">
				{Number(plan?.price) === 0 ? (
					<div className={styles['plan-price']}>{t('free')}</div>
				) : (
					<div className={`flexbox ${styles['price-wrap']}`}>
						<div className={styles['plan-currency']}>{plan?.currency}</div>
						<div className={styles['plan-price']}>
							{Number(plan?.price)
							.toFixed(2)
							.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
							.replace('.00', '')}
						</div>
					</div>
				)}
				<div className={styles['plan-duration']}>{plan?.duration}</div>
			</div>
			<div className={styles['plan-benefits']}>
				{plan?.benefits?.map((benefit) => {
					return (
						<div
							key={benefit}
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{ __html: benefit?.replace('\n', '<br/>') }}
							className={styles['plan-benefit']}
						/>
					);
				})}
			</div>
			<div className={`${styles['btn-wrapper']} flexbox colls spacer lg`}>
				<button
					type="button"
					className="default-btn dark-purple"
					style={{
						marginBottom: '0.5em',
						padding: '0.7em 2em',
						whiteSpace: 'pre-wrap'
					}}
					onClick={() => {
						onPlanAction(plan);
					}}
				>
					{plan?.planbutton}
				</button>
				<div className={styles['plan-trial']}>{plan?.trial}</div>
			</div>
			<div
				className={`${styles['relplans-wrapper']}
				${!isRelatedPlans ? 'hidden' : ''} ${!isMultiplier ? styles.nomultiplier : ''}`}
			>
				{plan?.relatedplans?.length > 0 && (
					<div className={styles.description}>{t('save_more_plus')}</div>
				)}
				{plan?.relatedplans?.map((elt) => {
					return (
						<div
							key={`relatedplan-${elt.planid}`}
							className={`${styles['relatedplan-wrap']} flexbox corners`}
							onClick={() => {
								onPlanAction(elt);
							}}
						>
							<div className={styles['relplan-title']}>{elt.title}</div>
							<div className="flexbox centered">
								<div className="flexbox colls">
									{Number(elt.multiplier) !== 0}
									{elt.multiplier && Number(elt.multiplier) !== 0 ? (
										<div className={styles['relplan-multiplier']}>
											{elt.currency}
											{(Math.round(Number(elt.multiplier) * Number(elt.price) * 100) / 100)
											.toFixed(2)
											.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
											.replace('.00', '')}
										</div>
									) : null}
									<div className={styles['relplan-pricing']}>
										{elt.currency}
										{Number(elt.price)
										.toFixed(2)
										.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
										.replace('.00', '')}
									</div>
								</div>
								<div className={`${styles['relplan-arrow']} flexbox`}>
									<LazyLoadImage
										src={`${environment.web_cdn_url}web/assets/img/manageaccount/arrow.svg`}
									/>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

PlanComponent.propTypes = {
	plan: PropTypes.object.isRequired,
	isRelatedPlans: PropTypes.bool.isRequired,
	isMultiplier: PropTypes.bool.isRequired,
	onPlanAction: PropTypes.func
};

export default PlanComponent;
