import React from 'react';
import { IField, IImage } from '@interfaces/sections.interface';
import dynamic from 'next/dynamic';

interface CarouselProps {
	mainCarouselClass?: string;
	isDesktop?: boolean;
	isCarouselOnTop?: boolean;
	data: {
		title?: IField;
		subtitle?: IField;
		imageClass?: string;
		additionalTransfrom?: number;
	};
	backgroundColor?: string;
	images: IImage[];
	isDarkMode?: boolean;
	extraStyles?: any;
	wrapperStyle?: any;
}

const WebCarousel = dynamic(async () => import('./web-carousel'));
const MobileCarousel = dynamic(async () => import('./mobile-carousel'));

export const Carousel = ({
	mainCarouselClass,
	isDesktop,
	isCarouselOnTop,
	data,
	images,
	backgroundColor,
	isDarkMode,
	extraStyles = {},
	wrapperStyle
}: CarouselProps) => {
	return (
		<div style={wrapperStyle}>
			{isDesktop ? (
				<WebCarousel
					mainCarouselClass={mainCarouselClass}
					isCarouselOnTop={isCarouselOnTop}
					images={images}
					title={data?.title}
					subtitle={data?.subtitle}
					imageClass={data?.imageClass}
					additionalTransfrom={data?.additionalTransfrom}
					isDarkMode={isDarkMode}
					extraStyles={extraStyles}
				/>
			) : (
				<MobileCarousel
					mainCarouselClass={mainCarouselClass}
					images={images}
					title={data?.title}
					subtitle={data?.subtitle}
					backgroundColor={backgroundColor}
					imageClass={data?.imageClass}
					isCarouselOnTop={isCarouselOnTop}
					isDarkMode={isDarkMode}
				/>
			)}
		</div>
	);
};

export default Carousel;
